/* .home-page {
    /* background-image: url("../assetes/HomeBackground.png"); */
    /* width: 100%; */
    /* height: 230vh; */ 
        /* overflow-y: hidden; */
/* } */

  
  /* Default styles for larger screens (tablets and desktops) */

.home-page {
    background-image: url("../assetes/web.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    height: 150vh;
    /* animation:  zoomBackground 20s ease-in-out infinite; */
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  


  @keyframes moveBackground {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 100%;
    }
  }
  
  @keyframes zoomBackground {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }

 
  

  .ball-icon {
    width: 120px;
    height: 120px;
    object-fit: cover;
    animation: rotation 3s linear infinite;
    position: relative;
    left: 700px;
  
  }

  .input-container {
    display: flex;
    align-items: center;
    width: 700px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 5);
    background-color: #C9BBDF; /* Light purple background color */
    border: 2px solid #a349a4; /* Slightly darker purple border */
   
  }

  .submitButton{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .mainAnimation{
    padding-top: 0;
  }

  .socialLink{
    display: flex;
    align-items:center ;
    justify-content: space-around;
    padding-top: 190px;
  }

  

  
  /* Styles for mobile devices */
  @media (max-width: 668px) {
    .home-page {
      background-image: url("../assetes/figmaImg.png");
      /* background-size: contain; */
      /* background-repeat: round; */
      height: 155vh;
      object-fit: cover;
      width: 100%;
      /* animation:  zoomBackground 20s ease-in-out infinite; */
      overflow-x: hidden !important;
      overflow-y: hidden !important;
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 20px;
      padding-top: 115px;
    }
    .black-ball {
      width: 60px;
      height: 60px;
      object-fit: cover;
      animation: rotation 3s linear infinite;
      position: relative;
      right: 150px;
      /* top:100px */
    }
    .ball-icon {
      width: 120px;
      height: 120px;
      object-fit: cover;
      animation: rotation 3s linear infinite;
      position: relative;
      left: 170px;
    
    }
    .input-container {
      display: flex;
      align-items: center;
      width: 300px;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 5);
      background-color: #C9BBDF; /* Light purple background color */
      border: 2px solid #a349a4; /* Slightly darker purple border */
     
    }

    .arrow-button {
      background-color: orange;
      border: none;
      color: white;
      font-size: 18px;
      /* padding: 5px; */
      cursor: pointer;
      border-radius: 50%;
      width: 28px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .submitButton{
      display:flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }
    .mainAnimation{
      padding-top: 0px;
    }

    .socialLink{
      display: flex;
      align-items:center ;
      justify-content: space-around;
      padding-top: 185px;
    }

    .termsCondition{
      display: flex;
      align-items:center ;
      justify-content: space-around;
      margin-top: 45px;
    }

    .divText{
      margin-top: 20px;
      margin-left: 40px;
    }
  
  }

 
  @media (max-width: 375px) {
    .home-page {
        background-size: contain;
        height: 187vh;
        object-fit: cover;
        width: 100%;
        overflow-x: hidden !important;
        overflow-y: hidden !important;
        /* animation: zoomBackground 20s ease-in-out infinite; */
        background-repeat: round;
      }
  }

  
  @media (max-width: 375px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;
        padding-top: 240px;
      }
  }
  

  @media (max-width: 375px) {
 .submitButtom{
  display: flex;
    align-items: center;
    justify-content: center;
      margin-top: 150;
 }
}
  

  
  .icon {
    margin-right: 10px;
    color: #53249E;
  }
  
  .input {
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    color: #333;
    /* opacity: 0.5; */
  }
  
 
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

 
  .poll-ball-icon {
    width: 60px;
    height: 60px;
    object-fit: cover;
    animation: rotation 3s linear infinite;
    position: relative;
  }

/* .home-page {
    background-image: url("../assetes/HomeBackground.png");
    width: 100%;
    height: 200vh;
    background-position: center bottom; 
    background-attachment: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    overflow-y: hidden ;
    overflow-x: hidden !important;
    object-fit: cover;
    } */

.main-div {
    width: 100%; /* Ensure the container doesn't exceed viewport width */
    max-width: 100%; /* Optional: Limit the maximum width */
    overflow-x: visible; /* Ensure overflow is hidden */
    scroll-padding-right: 0;
}
 
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flash-bg-img {
    background-image: url("../assetes/16.png");
    height: 400px;
    width: 400px;
    position: relative;
    left: -50px;
    top: -900px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .submitbutton {
    background-image: url("../assetes/5.png");
} */

/* HTML: <div class="loader"></div> */
.loader {
    width: 38px;
    height: 38px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    
    

      .app {
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 10px; */
      }

    @media (max-width: 375px) {
      .app {
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 15px; */
      }  
    }
  
    
    .carousel-container {
      display: flex;
      align-items: center;
      width: 400px;
      height: 149px;
    }

    @media (max-width: 375px) {
    .carousel-container {
      display: flex;
      align-items: center;
      width: 350px;
      height: 149px;
    }
  }
    
    .carousel {
      display: flex;
      overflow: hidden;
      scroll-behavior: smooth;
      width: 440px;
      height: 149px;
    }
    
    .carousel-item {
      flex: 0 0 auto;
      width: 151px;
      height: 149px;
      text-align: center;
      margin: 0 5px; /* Adding gap between the items */
      position: relative;
      border-radius: 20px; /* Adding curved borders */
      overflow: hidden; /* To ensure the content stays within the rounded borders */
      background-color: #FFF;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    @media (min-width: 768px) {
      .termsCondition{
        display: flex;
        align-items:center ;
        justify-content: center;
        margin-top: 60px;
        gap: 355px;
      }

      .black-ball {
        width: 60px;
        height: 60px;
        object-fit: cover;
        animation: rotation 3s linear infinite;
        position: relative;
        right: 675px;
        top:100px
      }

      .divText{
        margin-top: 20px;
        margin-left: 430px;
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px;
        padding-top: 70px;
        margin-left: 20px;
      }
    
    
      .carousel-container {
        width: 48%; /* Increase the width for desktop */
      }
      
      .carousel {
        width: 100% /* Increase the width for desktop */
      }
      
      .carousel-item {
        width: 201px; /* Increase the item width for desktop */
        /* height: 199px; */
      }
      
      .carousel-item img {
        width: 160px;
        height: 160px;
        padding-bottom: 25px;
      }
      
      .carousel-item p {
        font-size: 18px;
        padding: 15px;
      }
      .arrow-button {
        background-color: orange;
        border: none;
        color: white;
        font-size: 18px;
        /* padding: 5px; */
        cursor: pointer;
        border-radius: 50%;
        width: 28px;
        /* height: 21px; */
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    
    
    .carousel-item img {
      width: 110px;
      height: 110px;
      object-fit:contain;
      padding-bottom:35px

    }
    
    .carousel-item p {
      margin: 0;
      font-size: 16px;
      padding:10px;
      font-weight:600;
      background-color: #F07632;
      color: black;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
    
    
    
    .arrow-button:focus {
      outline: none;
    }

    
    @media (max-width: 375px) {
      .termsCondition{
        display: flex;
        align-items:center ;
        justify-content: space-around;
        margin-top: 80px;
      }
    }

    
    @media (max-width: 390px) {
      .socialLink{
        display: flex;
        align-items:center ;
        justify-content: space-around;
        padding-top: 70px;
      }
    }
  

.rocket {
  position: absolute;
  width: 150px;
  height: 150px;
  transition: top 0.05s linear, left 0.05s linear;
  /* z-index: -1; */
}

.rocketModel{
  /* position:relative; */
}


    @keyframes moveUpDown {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-30px);
      }
    }

    .animated-image {
  /* animation: moveUpDown 10s infinite alternate; */
}


 
  
    .input::placeholder {
      color: black;
      font-weight: 600;
    }

    .text{
     color: #E39026;
     font-weight: 600;
     font-size: 25px;
     font-family: 'monospace';
    }

    

    .callus-image:hover {
      opacity: 0.5; /* Make the image slightly transparent */
      transform: scale(1.05); /* Scale up the image slightly */
      transition: all 0.3s ease; /* Smooth transition effect */
    }



    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
      z-index: 9999; /* ensure it's on top of other content */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      position: relative;
    }
    
    .close-button {
      position: absolute;
      /* top: 10px; */
      right: 30px;
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 1.5rem;
      padding: 0;
    }
    
    .close-button:hover {
      color: #ffc107; /* Change color on hover */
    }

    /* Update existing styles for consistency */
    .card-body {
      background-color: rgba(0, 0, 0, 0.7);
      padding: 20px;
    }
    
    .card-title {
      font-size: 1.25rem;
      color: #ffc107;
    }
    
    .list-group-item {
      background-color: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      border: none;
    }
    
    .list-group {
      margin-top: 10px;
    }

    .swal2-actions{
      display: none;
    }
    