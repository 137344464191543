.scanner-page{
    background-image: url("../assetes/recharge.png");
    width: 100%;
    height: 100vh;
    background-size: cover;
}

.scanner-wrapper {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scanner-bg-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* default styles */
  width: 300px;
  height: 300px;
  transition: width 0.3s ease, height 0.3s ease; /* Optional: for smooth transition */
}

.scanner-bg-img.scanning {
  width: 150px;
  height: 150px;
}

#reader {
   border: none !important ;

}

#reader text {
    border-top: none !important;
}

#reader button{
    color: black;
    background-color: #df8c38;
    padding: 10px;
    border-radius: 10px;
    font-weight: 300;
   font-family: fontzillion;
   /* display: none !important; */
  }

  #reader img {
    display: none; 
  }

  #reader a {
    display: none !important;
    /* color:white */
  }

  #reader input {
    /* color: black;
    background-color: #df8c38;
    padding: 1px;
    border-radius: 10px;
    font-weight: 300;
   font-family: fontzillion;
   border:none !important; */
}

#reader button{
  color: white;
    background-color: #53249E;
    padding: 10px;
    border-radius: 25px;
    font-weight: 300;
    font-family: fontzillion;
    /* display: none !important; */
    position: relative;
    bottom: 121px;
    width:220px
}

#reader__scan_region{
  width: 100%;
    min-height: 100px;
    text-align: center;
    position: relative;
    bottom: 248px;
}

