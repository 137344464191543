.home-page1 {
    background-image: url("../assetes/background2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    height: 300vh;
  }

  
  
  /* Styles for mobile devices */
  @media (max-width: 668px) {
    .home-page1 {
      /* background-size: contain; */
      /* background-repeat: round; */
      height: 167vh;
      object-fit: cover;    
      width: 100%;
    }
  }

  @media (max-width: 375px) {
    .home-page1 {
        background-size: cover;
        height: 187vh;
        object-fit: initial;
        width: 100%;
    }
  }

